import styled from "styled-components";
import theme from "../../../styles/theme";

export const HeaderWrapper = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
`;

export const Header = styled.div`
    @media only screen and (max-width: 480px) {
        width: 90%;
    }

    @media only screen and (min-width: 480px) {
        width: 66%;
    }

    @media only screen and (min-width: 1900px) {
        width: 50%;
    }

    align-items: center;
    border-radius: ${theme.spacing[100]};
    background-color: ${theme.surfaces[600]};
    display: flex;
    gap: ${theme.size[200]};
    flex-direction: row;
    justify-content: center;
    padding: ${theme.spacing[50]};
`;

export const ProfileImage = styled.img`
    background: transparent;
    border-radius: 50%;
    height: ${theme.size[800]};
    width: ${theme.size[800]};
    object-fit: cover;
`;

export const HeaderText = styled.h1`
    color: ${theme.surfaces[100]};
    font-size: ${theme.fonts[400]};
    font-weight: 700;
`;
