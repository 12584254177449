import React, { useEffect, useState } from "react";
import Header from "../../complex/header/Header";
import Cover from "../../complex/cover/Cover";
import { MainContainer } from "./styled";
import StoryCard from "../../templates/StoryCard/StoryCard";
import InformationCard from "../../templates/InformationCard/InformationCard";
import CompaniesCard from "../../templates/Companies/CompaniesCard";
import ServicesCard from "../../templates/Services/ServicesCard";
import ProjectsCard from "../../templates/Projects/ProjectsCard";
import ContactCard from "../../templates/Contact/ContactCard";
import Footer from "../../templates/Footer/Footer";

const MainPage = () => {
    const [currentSection, setCurrentSection] = useState<string>("home");

    useEffect(() => {
        const sections = document.querySelectorAll(".section");

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setCurrentSection(entry.target.id);
                    }
                });
            },
            {
                threshold: 0.5,
            }
        );

        sections.forEach((section) => {
            observer.observe(section);
        });

        return () => {
            sections.forEach((section) => {
                observer.unobserve(section);
            });
        };
    }, []);

    return (
        <MainContainer>
            <Header currentSection={currentSection} />
            <Cover />

            <StoryCard />
            <InformationCard />
            <CompaniesCard />
            <ServicesCard />
            <ProjectsCard />
            <ContactCard />

            <Footer />
        </MainContainer>
    );
};

export default MainPage;
