import React from "react";
import {
    ContactContainer,
    IconWrapper,
    InfoButton,
    InfoColumn,
    InfoContainer,
    InfoHeader,
    LeetCodeIcon,
    LinkedInIcon,
    MailLink,
} from "./styled";
import CardHeader from "../../complex/card-header/CardHeader";
import { Text } from "../StoryCard/styled";

const ContactCard = () => {
    return (
        <ContactContainer id="contact">
            <CardHeader
                imageSource="images/aerial-view-man-using-computer-laptop-wooden-table.jpg"
                text="Say Hi"
            />
            <InfoContainer>
                <InfoColumn>
                    <InfoHeader>SERVICES</InfoHeader>
                    <InfoButton href="#">Websites</InfoButton>
                    <InfoHeader>STAY CONNECTED</InfoHeader>
                    <IconWrapper
                        href="https://www.linkedin.com/in/raul-vaida-85157a195/"
                        target="_blank"
                    >
                        <LinkedInIcon />
                    </IconWrapper>
                    <IconWrapper
                        href="https://leetcode.com/u/vaidatc/"
                        target="_blank"
                    >
                        <LeetCodeIcon />
                    </IconWrapper>
                </InfoColumn>
                <InfoColumn>
                    <InfoHeader>ABOUT</InfoHeader>
                    <Text>
                        Raul is a professional full-stack web developer. Feel
                        free to send a message.
                    </Text>
                    <InfoHeader>EMAIL</InfoHeader>
                    <MailLink href="mailto:raultvaida@gmail.com">
                        raultvaida@gmail.com
                    </MailLink>
                </InfoColumn>
            </InfoContainer>
        </ContactContainer>
    );
};

export default ContactCard;
