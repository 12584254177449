const theme = {
    fonts: {
        50: "0.6rem",
        75: "1rem",
        100: "1.2rem",
        150: "1.4rem",
        200: "1.8rem",
        300: "2.2rem",
        400: "2.6rem",
    },
    size: {
        200: "0.8rem",
        250: "1.4rem",
        300: "2rem",
        400: "3.2rem",
        500: "4.8rem",
        600: "6rem",
        700: "7.2rem",
        800: "8.4rem",
        900: "9.6rem",
        1000: "10.8rem",
    },

    spacing: {
        50: "0.6rem",
        100: "1.2rem",
        200: "1.6rem",
        300: "3.2rem",
        400: "4.8rem",
        desktopBreakpoint: 992,
    },

    surfaces: {
        100: "#ffffff",
        125: "#f9f9f9",
        150: "#e0e0e0",
        200: "#717275",
        300: "#de5246",
        350: "#7b1e16",
        400: "#1c98bf",
        500: "#15728f",
        600: "#14b789",
        700: "#4F6A66",
    },
};
export default theme;
