import React from "react";
import { Header, HeaderText, HeaderWrapper, ProfileImage } from "./styled";

interface ICardProps {
    imageSource: string;
    text: string;
}

const CardHeader = ({ imageSource, text }: ICardProps) => {
    return (
        <HeaderWrapper>
            <Header>
                <ProfileImage src={imageSource} />
                <HeaderText>{text}</HeaderText>
            </Header>
        </HeaderWrapper>
    );
};

export default CardHeader;
