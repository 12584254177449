import { styled } from "styled-components";
import theme from "../../../styles/theme";

export const CoverContainer = styled.div`
    align-items: center;
    background-image: url("/images/Wallpaper.jpg");
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -${theme.size[900]};
`;

export const CoverContent = styled.div`
    align-items: center;
    display: flex;
    flex: 1;

    @media only screen and (min-width: 1900px) {
        width: 66%;
    }
`;

export const QuoteArea = styled.div`
    margin-left: ${theme.size[300]};
    padding: ${theme.size[200]};
`;

export const QuoteContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${theme.size[200]};
    padding: ${theme.size[200]};
`;

export const ProfilePicture = styled.img`
    background: transparent;
    border-radius: 50%;
    height: ${theme.size[600]};
    width: ${theme.size[600]};
    object-fit: cover;
`;

export const Message = styled.div`
    display: flex;
    align-items: center;
    border-radius: ${theme.size[400]};
    background-color: ${theme.surfaces[100]};
    color: black;
    font-size: ${theme.fonts[400]};
    font-weight: 700;
    padding: ${theme.spacing[100]};
`;

export const Paragraph = styled.div`
    color: ${theme.surfaces[100]};
    font-size: ${theme.size[300]};
    font-weight: 700;
    padding: ${theme.size[200]};
`;

export const BeginButton = styled.button`
    background-color: ${theme.surfaces[700]};
    border: 1px solid ${theme.surfaces[100]};
    border-radius: ${theme.spacing[300]};
    color: ${theme.surfaces[100]};
    font-size: ${theme.size[250]};
    padding: ${theme.spacing[100]};
    transition: 0.25s;
    width: fit-content;

    &:hover {
        cursor: pointer;
        background-color: ${theme.surfaces[600]};
    }
`;
