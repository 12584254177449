import styled from "styled-components";
import theme from "../../../styles/theme";

export const ServicesContainer = styled.div`
    align-items: center;
    background-color: ${theme.surfaces[125]};
    border-bottom: 1px solid ${theme.surfaces[150]};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${theme.size[700]} 0px ${theme.size[700]} 0px;

    @media only screen and (max-width: 480px) {
        padding: ${theme.size[400]} 0px ${theme.size[400]} 0px;
        margin-top: 0px;
    }
`;

export const WebsitesContainer = styled.div`
    @media only screen and (max-width: 480px) {
        width: 90%;
    }

    @media only screen and (min-width: 480px) {
        width: 66%;
    }

    @media only screen and (min-width: 1900px) {
        width: 50%;
    }

    background-color: ${theme.surfaces[100]};
    border: 1px solid ${theme.surfaces[150]};
    border-radius: ${theme.spacing[100]};
    margin-top: ${theme.spacing[300]};
`;

export const Websites = styled.div`
    padding: ${theme.spacing[300]} ${theme.spacing[300]};
`;

export const WebsitesTitle = styled.h1`
    border-bottom: 1px solid ${theme.surfaces[150]};
    font-size: ${theme.fonts[200]};
    font-weight: 700;
    padding-bottom: ${theme.size[200]};
`;

export const WebsitesText = styled.div``;

export const SitesContainer = styled.div`
    display: grid;

    @media only screen and (max-width: 480px) {
        gap: ${theme.spacing[200]};
        width: 100%;
    }

    @media only screen and (min-width: 480px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: ${theme.spacing[200]};
    }
`;

export const SitePreviewWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const SitePreview = styled.img`
    border: 1px solid ${theme.surfaces[600]};
    height: ${theme.size[900]};
    transition: 0.25s;

    @media only screen and (min-width: 1900px) {
        height: ${theme.size[1000]};
    }

    &:hover {
        cursor: pointer;
        transform: scale(1.2);
    }
`;
