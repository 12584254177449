import React from "react";
import {
    ServicesContainer,
    SitePreview,
    SitePreviewWrapper,
    SitesContainer,
    Websites,
    WebsitesContainer,
    WebsitesText,
    WebsitesTitle,
} from "./styled";
import CardHeader from "../../complex/card-header/CardHeader";
import { Text } from "../StoryCard/styled";

const ServicesCard = () => {
    return (
        <ServicesContainer id="services" className="section">
            <CardHeader
                imageSource="images/handshake-man-woman-after-signing-business-contract-closeup.jpg"
                text="Services"
            />
            <WebsitesContainer>
                <Websites>
                    <WebsitesTitle>Websites</WebsitesTitle>
                    <WebsitesText>
                        <Text>
                            Explore some of the websites I've created during my
                            courses and in my free time.
                        </Text>
                        <Text>
                            These sites are basic examples that showcase the
                            general skills I've developed while working on them.
                        </Text>
                        <Text>
                            They are hosted for free on Render, so they might
                            load a bit slowly and may occasionally be down as
                            they are not commercial grade.
                        </Text>
                    </WebsitesText>
                    <SitesContainer>
                        <SitePreviewWrapper>
                            <a
                                href="https://task-tracker-angular-two.vercel.app/signup"
                                target="_blank"
                            >
                                <SitePreview
                                    src={"images/projects/task_tracker.png"}
                                />
                            </a>
                        </SitePreviewWrapper>

                        <SitePreviewWrapper>
                            <a
                                href="https://expensify-app-flax.vercel.app/"
                                target="_blank"
                            >
                                <SitePreview
                                    src={"images/projects/expensify.png"}
                                />
                            </a>
                        </SitePreviewWrapper>
                        <SitePreviewWrapper>
                            <a
                                href="https://raul-node-weather-app.onrender.com/"
                                target="_blank"
                            >
                                <SitePreview
                                    src={"images/projects/weather.png"}
                                />
                            </a>
                        </SitePreviewWrapper>

                        <SitePreviewWrapper>
                            <a
                                href="https://raul-node-chat-app.onrender.com/"
                                target="_blank"
                            >
                                <SitePreview src={"images/projects/chat.png"} />
                            </a>
                        </SitePreviewWrapper>
                    </SitesContainer>
                </Websites>
            </WebsitesContainer>
        </ServicesContainer>
    );
};

export default ServicesCard;
