import React from "react";
import { StoryCardContainer, ContentContainer, SubTitle, Text } from "./styled";
import CardHeader from "../../complex/card-header/CardHeader";

const StoryCard = () => {
    return (
        <StoryCardContainer id="about" className="section">
            <CardHeader imageSource="/images/raul.jpg" text="My Story" />

            <ContentContainer>
                <SubTitle>...a little bit about Raul</SubTitle>
                <Text>
                    Raul has been tech-savvy his entire life, starting from a
                    young age by taking apart electronics and installing
                    software on the family computer.
                </Text>
                <Text>
                    Raul received top-tier education in the city of Arad,
                    excelling in school olympics for math and physics. He
                    discovered his true passion for programming in high school
                    and pursued it at the Polytechnics University of Timișoara,
                    where he earned a Bachelor's degree in System Engineering.
                </Text>
                <Text>
                    After graduating from university, Raul began his career at
                    Continental Automotive, working on cutting-edge advanced
                    driver assistance systems and gaining experience in a
                    corporate environment. He then transitioned to Endava, where
                    he pursued his true passion for web development. At Endava,
                    Raul advanced to the role of Fullstack Web Developer,
                    collaborating with major clients in the insurance and
                    banking industries.
                </Text>
                <Text>
                    Raul is also a dedicated teacher, committed to helping
                    individuals transition to careers in programming. His
                    teaching experience has enhanced his people skills and
                    reinforced his understanding of programming fundamentals.
                    This period also marked the inception of his company,
                    enabling him to legally undertake freelance projects.
                </Text>
            </ContentContainer>
        </StoryCardContainer>
    );
};

export default StoryCard;
