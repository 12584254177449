import styled from "styled-components";
import theme from "../../../styles/theme";

export const InfoWrapper = styled.div`
    align-items: center;
    border-bottom: 1px solid ${theme.surfaces[150]};
    display: flex;
    justify-content: center;
    background-color: ${theme.surfaces[125]};
    padding: ${theme.size[700]} 0px ${theme.size[700]} 0px;

    @media only screen and (max-width: 480px) {
        padding: ${theme.size[400]} 0px ${theme.size[400]} 0px;
    }
`;

export const InfoContainer = styled.div`
    @media only screen and (max-width: 480px) {
        flex-direction: column;
    }
    display: flex;
    gap: ${theme.spacing[100]};
    justify-content: space-between;
    min-width: 50%;
`;

export const InfoTable = styled.div`
    border: 1px solid ${theme.surfaces[150]};
    border-radius: ${theme.size[300]};
    background-color: ${theme.surfaces[100]};
    display: flex;
    flex: 1;
    flex-direction: column;
`;

export const TableHead = styled.div`
    border-bottom: 1px solid ${theme.surfaces[150]};
    font-size: ${theme.fonts[300]};
    font-weight: 700;
    padding: ${theme.size[300]};
`;

export const TableBody = styled.div`
    display: flex;
    flex-direction: row;
`;

export const TableColumn = styled.div`
    width: 100%;
`;

export const TableFirstColumnElement = styled.div<{ $staysCentered?: boolean }>`
    background-color: ${(props) =>
        props.$staysCentered ? theme.surfaces[125] : ""};
    border-right: 1px solid ${theme.surfaces[150]};
    color: ${theme.surfaces[600]};
    font-size: ${theme.fonts[100]};
    font-weight: 600;
    padding: ${theme.size[300]};
`;

export const TableSecondColumnElement = styled.div<{
    $staysCentered?: boolean;
}>`
    background-color: ${(props) =>
        props.$staysCentered ? theme.surfaces[125] : ""};
    color: ${theme.surfaces[200]};
    font-size: ${theme.fonts[100]};
    font-weight: 300;
    padding: ${theme.size[300]};
`;

export const TableMailLink = styled.a`
    color: ${theme.surfaces[200]};
    text-decoration: none;
    transition: 0.25s;

    &:hover {
        color: ${theme.surfaces[600]};
    }
`;

export const NumbersContainer = styled.div``;

export const YearsProjectsContainer = styled.div`
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: row;
`;

export const NumberTextContainer = styled.div<{ $staysLeft?: boolean }>`
    border-bottom: 1px solid ${theme.surfaces[150]};
    border-left: ${(props) =>
        props.$staysLeft ? "" : `1px solid ${theme.surfaces[150]}`};
    height: fit-content;
    padding: ${theme.spacing[100]};
`;

export const NumberHeader = styled.h1`
    font-size: ${theme.fonts[300]};
    font-weight: 700;
`;
export const NumberSubText = styled.h2`
    color: ${theme.surfaces[600]};
    font-size: ${theme.fonts[100]};
    font-weight: 300;
`;
