import styled from "styled-components";
import theme from "../../../styles/theme";

export const StoryCardContainer = styled.div`
    align-items: center;
    border-bottom: 1px solid ${theme.surfaces[150]};
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: ${theme.size[700]} 0px ${theme.size[700]} 0px;
    margin-top: ${theme.spacing[400]};

    @media only screen and (max-width: 480px) {
        padding: ${theme.size[400]} 0px ${theme.size[400]} 0px;
        margin-top: 0px;
    }
`;

export const ContentContainer = styled.div`
    align-items: center;
    width: 66%;

    @media only screen and (max-width: 480px) {
        width: 80%;
    }

    @media only screen and (min-width: 1900px) {
        width: 50%;
    }
`;

export const SubTitle = styled.h2`
    font-size: ${theme.fonts[300]};
    font-weight: 700;
`;

export const Text = styled.p`
    color: ${theme.surfaces[200]};
    font-size: ${theme.fonts[100]};
    line-height: ${theme.size[300]};
`;
