import React from "react";
import {
    Card,
    CardImage,
    CardSubtitle,
    CardTitle,
    CardsContainer,
    ProjectsContainer,
} from "./styled";
import CardHeader from "../../complex/card-header/CardHeader";

const ProjectsCard = () => {
    return (
        <ProjectsContainer id="projects" className="section">
            <CardHeader
                imageSource="images/white-desk-work-study-aesthetics.jpg"
                text="Projects"
            />

            <CardsContainer>
                <Card>
                    <CardSubtitle>CAR INTEGRATED SYSTEMS</CardSubtitle>
                    <CardTitle>ADAS in Automotive</CardTitle>

                    <CardImage src={"images/projects/adas.jpg"} />
                </Card>

                <Card>
                    <CardSubtitle>INTERNAL CLIENT WEBSITE</CardSubtitle>
                    <CardTitle>Insurance Companies</CardTitle>

                    <CardImage src={"images/projects/hurricane.webp"} />
                </Card>

                <Card>
                    <CardSubtitle>INTERNAL CLIENT WEBSITE</CardSubtitle>
                    <CardTitle>Banking Transactions</CardTitle>

                    <CardImage src={"images/projects/banking.jpg"} />
                </Card>
            </CardsContainer>
        </ProjectsContainer>
    );
};

export default ProjectsCard;
