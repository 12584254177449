import styled from "styled-components";
import theme from "../../../styles/theme";
import { TiSocialLinkedinCircular } from "react-icons/ti";
import { TbBrandLeetcode } from "react-icons/tb";

export const ContactContainer = styled.div`
    align-items: center;
    background-color: ${theme.surfaces[125]};
    border-bottom: 1px solid ${theme.surfaces[150]};
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: ${theme.size[700]} 0px ${theme.size[700]} 0px;

    @media only screen and (max-width: 480px) {
        padding: ${theme.size[400]} 0px ${theme.size[400]} 0px;
        margin-top: 0px;
    }
`;

export const InfoContainer = styled.div`
    display: flex;
    margin-top: ${theme.spacing[300]};

    @media only screen and (max-width: 480px) {
        justify-content: center;
        width: 90%;
    }

    @media only screen and (min-width: 480px) {
        justify-content: space-between;
        width: 66%;
    }

    @media only screen and (min-width: 1900px) {
        width: 50%;
    }
`;

export const InfoColumn = styled.div`
    background-color: ${theme.surfaces[100]};
    flex: 0.499;
    padding: ${theme.spacing[200]};
`;

export const InfoHeader = styled.h2`
    font-size: ${theme.fonts[100]};
    font-weight: 700;
`;

export const InfoButton = styled.a`
    border: 1px solid ${theme.surfaces[150]};
    border-radius: ${theme.spacing[200]};
    color: ${theme.surfaces[200]};
    font-size: ${theme.fonts[100]};
    font-weight: 300;
    padding: ${theme.spacing[50]};
    transition: all ease 0.25s;
    text-decoration: none;
    width: fit-content;

    &:hover {
        border: 1px solid ${theme.surfaces[600]};
        cursor: pointer;
        color: ${theme.surfaces[100]};
        background-color: ${theme.surfaces[600]};
    }
`;

export const MailLink = styled.a`
    color: ${theme.surfaces[200]};
    font-size: ${theme.fonts[100]};
    font-weight: 300;
    text-decoration: none;
    transition: 0.25s;

    &:hover {
        color: ${theme.surfaces[600]};
    }
`;

export const IconWrapper = styled.a`
    color: inherit;
`;

export const LinkedInIcon = styled(TiSocialLinkedinCircular)`
    font-size: ${theme.fonts[400]};
    transition: all ease 0.25s;

    &:hover {
        cursor: pointer;
        color: ${theme.surfaces[600]};
    }
`;

export const LeetCodeIcon = styled(TbBrandLeetcode)`
    font-size: ${theme.fonts[400]};
    transition: all ease 0.25s;

    &:hover {
        cursor: pointer;
        color: ${theme.surfaces[600]};
    }
`;
