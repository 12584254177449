import styled from "styled-components";
import theme from "../../../styles/theme";

export const FooterContainer = styled.div`
    align-items: center;
    padding: ${theme.spacing[300]};
    display: flex;
    justify-content: center;
`;

export const FooterSpan = styled.span`
    color: ${theme.surfaces[200]};
    font-size: ${theme.fonts[75]};
    font-weight: 300;
`;

export const FooterAnchor = styled.a`
    color: inherit;
    text-decoration: none;
    font-size: ${theme.fonts[75]};
    font-weight: 700;
    transition: 0.25s;

    &:hover {
        cursor: pointer;
        color: ${theme.surfaces[600]};
    }
`;
