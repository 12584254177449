import { createGlobalStyle } from "styled-components";
import theme from "./theme";

const GlobalStyle = createGlobalStyle`
::selection{
    color: ${theme.surfaces[100]};
    background-color: ${theme.surfaces[600]};
}
`;

export default GlobalStyle;
