import React, { useState, useEffect } from "react";
import {
    HeaderContainer,
    HeaderMenuItem,
    HeaderTitlesRow,
    HeaderTitle,
    HeaderRow,
    Email,
    EmailWrapper,
} from "./styled";

export const handleScrollClick = (
    e: React.ChangeEvent<any>,
    sectionId: string
) => {
    e.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) section.scrollIntoView({ behavior: "smooth" });
};

const Header = ({ currentSection }) => {
    const [isScrolled, setIsScrolled] = useState<boolean>(false);

    const listenScrollEvent = () => {
        if (window.scrollY > 200) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", listenScrollEvent);
    }, []);

    return (
        <HeaderContainer $scrolled={isScrolled}>
            <HeaderRow $scrolled={isScrolled}>
                <HeaderTitle
                    $scrolled={isScrolled}
                    onClick={(e: React.ChangeEvent<any>) =>
                        handleScrollClick(e, "home")
                    }
                >
                    Personal Playground
                </HeaderTitle>

                <HeaderTitlesRow>
                    <HeaderMenuItem
                        $scrolled={isScrolled}
                        selected={currentSection === "home" ? true : false}
                        onClick={(e: React.ChangeEvent<any>) =>
                            handleScrollClick(e, "home")
                        }
                    >
                        Home
                    </HeaderMenuItem>
                    <HeaderMenuItem
                        $scrolled={isScrolled}
                        selected={currentSection === "about" ? true : false}
                        onClick={(e: React.ChangeEvent<any>) =>
                            handleScrollClick(e, "about")
                        }
                    >
                        About
                    </HeaderMenuItem>
                    <HeaderMenuItem
                        $scrolled={isScrolled}
                        selected={currentSection === "services" ? true : false}
                        onClick={(e: React.ChangeEvent<any>) =>
                            handleScrollClick(e, "services")
                        }
                    >
                        Services
                    </HeaderMenuItem>
                    <HeaderMenuItem
                        $scrolled={isScrolled}
                        selected={currentSection === "projects" ? true : false}
                        onClick={(e: React.ChangeEvent<any>) =>
                            handleScrollClick(e, "projects")
                        }
                    >
                        Projects
                    </HeaderMenuItem>

                    <HeaderMenuItem
                        $scrolled={isScrolled}
                        selected={currentSection === "contact" ? true : false}
                        onClick={(e: React.ChangeEvent<any>) =>
                            handleScrollClick(e, "contact")
                        }
                    >
                        Contact
                    </HeaderMenuItem>
                </HeaderTitlesRow>
                <EmailWrapper>
                    <Email
                        href="mailto:raultvaida@gmail.com"
                        $scrolled={isScrolled}
                    >
                        raultvaida@gmail.com
                    </Email>
                </EmailWrapper>
            </HeaderRow>
        </HeaderContainer>
    );
};

export default Header;
