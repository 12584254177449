import styled from "styled-components";
import theme from "../../../styles/theme";

export const CompaniesContainer = styled.div`
    align-items: center;
    border-bottom: 1px solid ${theme.surfaces[150]};
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: ${theme.size[700]} 0px ${theme.size[700]} 0px;

    @media only screen and (max-width: 480px) {
        padding: ${theme.size[400]} 0px ${theme.size[400]} 0px;
    }
`;

export const ImagesWrapper = styled.div`
    @media only screen and (max-width: 480px) {
        width: 90%;
    }

    @media only screen and (min-width: 480px) {
        width: 66%;
    }

    @media only screen and (min-width: 1900px) {
        width: 40%;
    }

    display: flex;
    justify-content: space-between;
`;

export const CompaniesTitle = styled.h1`
    font-size: ${theme.fonts[300]};
    font-weight: 700;
    padding: ${theme.size[300]};
`;

export const CompanyLogo = styled.img`
    @media only screen and (max-width: 480px) {
        height: ${theme.size[500]};
    }

    @media only screen and (min-width: 480px) {
        height: ${theme.size[600]};
    }

    transition: 0.25s;

    &:hover {
        transform: scale(1.3);
    }
`;
