import React from "react";
import {
    InfoContainer,
    InfoTable,
    InfoWrapper,
    NumberHeader,
    NumberSubText,
    NumberTextContainer,
    NumbersContainer,
    TableBody,
    TableColumn,
    TableFirstColumnElement,
    TableHead,
    TableMailLink,
    TableSecondColumnElement,
    YearsProjectsContainer,
} from "./styled";

export const InformationCard = () => {
    return (
        <InfoWrapper id="about" className="section">
            <InfoContainer>
                <InfoTable>
                    <TableHead>Information</TableHead>
                    <TableBody>
                        <TableColumn>
                            <TableFirstColumnElement $staysCentered={true}>
                                Name
                            </TableFirstColumnElement>
                            <TableFirstColumnElement>
                                Email
                            </TableFirstColumnElement>
                        </TableColumn>
                        <TableColumn>
                            <TableSecondColumnElement $staysCentered={true}>
                                Raul Vaida
                            </TableSecondColumnElement>
                            <TableSecondColumnElement>
                                <TableMailLink href="mailto:raultvaida@gmail.com">
                                    raultvaida@gmail.com
                                </TableMailLink>
                            </TableSecondColumnElement>
                        </TableColumn>
                    </TableBody>
                </InfoTable>
                <NumbersContainer>
                    <YearsProjectsContainer>
                        <NumberTextContainer $staysLeft={true}>
                            <NumberHeader>4+</NumberHeader>
                            <NumberSubText>
                                Years of Work Experience
                            </NumberSubText>
                        </NumberTextContainer>
                        <NumberTextContainer>
                            <NumberHeader>5</NumberHeader>
                            <NumberSubText>
                                Major Projects Finished
                            </NumberSubText>
                        </NumberTextContainer>
                    </YearsProjectsContainer>
                    <NumberTextContainer $staysLeft={true}>
                        <NumberHeader>6+</NumberHeader>
                        <NumberSubText>
                            Months of Teaching Experience
                        </NumberSubText>
                    </NumberTextContainer>
                </NumbersContainer>
            </InfoContainer>
        </InfoWrapper>
    );
};

export default InformationCard;
