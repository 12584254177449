import React from "react";
import {
    CompaniesContainer,
    CompaniesTitle,
    CompanyLogo,
    ImagesWrapper,
} from "./styled";

const CompaniesCard = () => {
    return (
        <CompaniesContainer id="about">
            <CompaniesTitle>Companies that I've worked for</CompaniesTitle>
            <ImagesWrapper>
                <CompanyLogo src={"images/clients/Conti.png"} />
                <CompanyLogo src={"images/clients/Endava.png"} />
            </ImagesWrapper>
        </CompaniesContainer>
    );
};

export default CompaniesCard;
