import { styled } from "styled-components";
import theme from "../../../styles/theme";

export const HeaderContainer = styled.div<{
    $scrolled: boolean;
}>`
    background-image: ${(props) =>
        !props.$scrolled
            ? "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))"
            : ""};

    display: flex;
    flex-direction: row;
    padding-bottom: ${theme.spacing[100]};
    width: 100%;
    position: sticky;
    padding-top: ${theme.spacing[50]};
    justify-content: center;
    top: 0;
    transition: all 0.3s;
    z-index: 999;

    @media only screen and (max-width: 480px) {
        position: relative;
    }
`;

export const HeaderRow = styled.div<{
    $scrolled: boolean;
}>`
    @media only screen and (max-width: 480px) {
        background: ${theme.surfaces[100]};
        flex-direction: column;
    }

    @media only screen and (min-width: 480px) {
        flex-direction: row;
    }

    @media only screen and (min-width: 1900px) {
        width: 66%;
    }

    align-items: center;
    // This is a necessity for the transition
    // If the transition is not needed this can get refactored
    background: ${(props) => (props.$scrolled ? theme.surfaces[100] : "")};
    box-shadow: ${(props) =>
        props.$scrolled ? "0 1rem 3rem rgb(0 0 0 / 18%)" : "none"};
    border-radius: ${theme.spacing[200]};
    display: flex;
    transition: 0.25s;
    width: 90%;
`;

export const HeaderTitle = styled.div<{
    $scrolled: boolean;
}>`
    @media only screen and (max-width: 480px) {
        color: black;
    }

    color: ${(props) => (props.$scrolled ? "black" : theme.surfaces[100])};
    font-size: ${theme.size[300]};
    font-weight: 700;
    flex: 0.33;
    padding: ${theme.spacing[100]};
    transition: 0.25s;

    &:hover {
        color: ${theme.surfaces[600]};
        cursor: pointer;
    }
`;

export const HeaderTitlesRow = styled.div`
    @media only screen and (max-width: 480px) {
        display: none;
    }

    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: ${theme.spacing[100]};
    flex: 0.33;
`;

export const HeaderMenuItem = styled.h2<{
    selected?: boolean;
    $scrolled?: boolean;
}>`
    border-bottom: ${(props) =>
        props.selected ? `2px solid ${theme.surfaces[600]}` : ""};
    color: ${(props) =>
        props.selected
            ? theme.surfaces[600]
            : props.$scrolled
            ? "black"
            : theme.surfaces[100]};
    font-size: ${theme.fonts[100]};
    font-weight: 300;

    &:hover {
        cursor: pointer;
        border-bottom: 2px solid ${theme.surfaces[600]};
        color: ${theme.surfaces[600]};
    }
`;

export const EmailWrapper = styled.div`
    align-items: center;
    display: flex;
    flex: 0.33;
    justify-content: end;
`;

export const Email = styled.a<{
    $scrolled?: boolean;
}>`
    @media only screen and (max-width: 480px) {
        border: 2px solid ${theme.surfaces[600]};
        color: ${theme.surfaces[600]};
    }

    border: 2px solid
        ${(props) =>
            props.$scrolled ? theme.surfaces[600] : theme.surfaces[100]};
    border-radius: ${theme.spacing[400]};
    color: ${(props) =>
        props.$scrolled ? theme.surfaces[600] : theme.surfaces[100]};
    padding: ${theme.spacing[100]};
    margin: ${theme.spacing[100]};
    text-decoration: none;
    transition: 0.25s;

    &:hover {
        border: 2px solid ${theme.surfaces[700]};
        color: ${theme.surfaces[700]};
        cursor: pointer;
    }
`;
