import React from "react";
import { FooterAnchor, FooterContainer, FooterSpan } from "./styled";

const Footer = () => {
    return (
        <FooterContainer>
            <FooterSpan>
                Copyright © 2023{" "}
                <FooterAnchor href="#">
                    Raul Vaida's Personal Playground
                </FooterAnchor>
            </FooterSpan>
        </FooterContainer>
    );
};

export default Footer;
