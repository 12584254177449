import "./styles/styles.css";
import "normalize.css/normalize.css";
import React from "react";

import { createRoot } from "react-dom/client";
import GlobalStyle from "../src/styles/globalStyles";
import MainPage from "./components/pages/main/MainPage";
import { Analytics } from "@vercel/analytics/react";

const container = document.getElementById("root")!;
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <GlobalStyle />
        <MainPage />
        <Analytics />
    </React.StrictMode>
);
