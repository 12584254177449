import styled from "styled-components";
import theme from "../../../styles/theme";

export const ProjectsContainer = styled.div`
    align-items: center;
    border-bottom: 1px solid ${theme.surfaces[150]};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${theme.size[700]} 0px ${theme.size[700]} 0px;

    @media only screen and (max-width: 480px) {
        padding: ${theme.size[400]} 0px ${theme.size[400]} 0px;
        margin-top: 0px;
    }
`;

export const CardsContainer = styled.div`
    display: flex;
    margin-top: ${theme.spacing[300]};

    @media only screen and (max-width: 480px) {
        flex-direction: column;
        gap: ${theme.spacing[100]};
        width: 90%;
    }

    @media only screen and (min-width: 480px) {
        flex-direction: row;
        gap: ${theme.spacing[100]};
        width: 66%;
    }

    @media only screen and (min-width: 1900px) {
        width: 50%;
    }
`;

export const CardTitle = styled.h1`
    font-size: ${theme.fonts[200]};
    font-weight: 700;
`;

export const CardSubtitle = styled.h4`
    color: ${theme.surfaces[600]};
    font-size: ${theme.fonts[75]};
    font-weight: 300;
`;

export const CardImage = styled.img`
    transform: rotate(10deg) translateY(80px);
    transition: all ease 0.5s;
    max-width: 100%;
`;

export const Card = styled.div`
    background-color: ${theme.surfaces[125]};
    border: 2px solid ${theme.surfaces[100]};
    border-radius: ${theme.spacing[100]};
    display: block;
    overflow: hidden;
    padding: ${theme.spacing[200]};
    transition: all ease 0.5s;

    @media only screen and (min-width: 480px) {
        flex: 0.33;
    }

    &:hover {
        cursor: pointer;
        border: 2px solid ${theme.surfaces[600]};
        transform: rotate(0) translateY(0);
    }

    &:hover ${CardImage} {
        transform: rotate(0) translateY(0);
    }
`;
