import React from "react";
import {
    BeginButton,
    CoverContainer,
    CoverContent,
    Message,
    Paragraph,
    ProfilePicture,
    QuoteArea,
    QuoteContainer,
} from "./styled";
import { handleScrollClick } from "../header/Header";

const Cover = () => {
    return (
        <CoverContainer id="home" className="section">
            <CoverContent>
                <QuoteArea>
                    <QuoteContainer>
                        <ProfilePicture src={"/images/raul.jpg"} />
                        <Message>Welcome to my personal site!</Message>
                    </QuoteContainer>
                    <Paragraph>Find more about me and my projects</Paragraph>
                    <BeginButton
                        onClick={(e: React.ChangeEvent<any>) =>
                            handleScrollClick(e, "about")
                        }
                    >
                        Let's Begin
                    </BeginButton>
                </QuoteArea>
            </CoverContent>
        </CoverContainer>
    );
};

export default Cover;
